<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-arrow-right-bold"
          title="Pesan"
          :value="String(summary.total)"
          style="cursor: pointer;"
          @click.native="setStatus('')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-arrow-right-bold"
          title="Pesan Hari Ini"
          :value="String(summary.now)"
          style="cursor: pointer;"
          @click.native="setStatus('unread')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-arrow-right-bold"
          title="Pesan Dibaca"
          :value="String(summary.dibaca)"
          style="cursor: pointer;"
          @click.native="setStatus('read')"
        />
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
      >
        <v-text-field
          v-model="q"
          append-icon="mdi-magnify"
          :label="$t('search')"
          color="secondary"
          hide-details
          @keyup="search"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        md="2"
        lg="2"
        offset-md="6"
        offset-lg="6"
      >
        <v-btn
          left
          width="100%"
          color="primary"
          to="/pesan/create"
        >
          <span class="mr-1">
            <v-icon light>mdi-pencil-plus</v-icon>
          </span>
          Buat Baru
        </v-btn>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="12"
      >
        <base-material-card
          icon="mdi-facebook-messenger"
          :title="status !== '' ? 'Pesan - ' + status : 'Pesan - All'"
          color="primary"
          class="px-6 py-3"
        >
          <template>
            <div
              v-if="!isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <div v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left primary--text">
                        #
                      </th>
                      <th class="text-left primary--text">
                        Komunitas
                      </th>
                      <th class="text-left primary--text">
                        Isi Pesan
                      </th>
                      <th class="text-left primary--text">
                        Dari
                      </th>
                      <th class="text-left primary--text">
                        Dikirim Ke
                      </th>
                      <th class="text-left primary--text">
                        Tgl.Kirim
                      </th>
                      <th class="text-left primary--text">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <!--  -->
                  <tbody>
                    <tr
                      v-for="(x, index) in Pesan.data"
                      :key="x.id"
                    >
                      <td>{{ index + Pesan.meta.from }}</td>
                      <td>{{ x.type }}</td>
                      <td>{{ x.text }}</td>
                      <td>{{ x.from ? x.from.phone : '-' }}</td>
                      <td>{{ x.to ? x.to.phone : '-' }}</td>
                      <td>
                        {{ x.created_at | moment('D MMM YYYY') }}
                      </td>
                      <td tyle="text-transform: capitalize">
                        {{ x.status }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="Pesan.meta.last_page"
                  total-visible="5"
                  @input="getResults"
                />
              </div>
            </div>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'Pesan',
    data () {
      return {
        Pesan: {
          data: {
            type: '',
            to: {
              phone: '',
            },
            from: {
              phone: '',
            },
          },
          meta: {},
        },
        summary: {
          total: 0,
          now: 0,
          unread: 0,
          dibaca: 0,
        },
        isLoading: false,
        page: parseInt(this.$route.query.page) || 1,
        q: this.$route.query.q || '',
        status: this.$route.query.status || 'read',
      }
    },
    watch: {
      status (newVal) {
        this.$router.push({ query: { ...this.$route.query, status: newVal } })
      },
      '$route.query.status': function (val) {
        this.status = val
      },
      page (newVal) {
        this.$router.push({ query: { ...this.$route.query, page: newVal } })
      },
      '$route.query.page': function (val) {
        this.page = parseInt(val)
      },
      q (newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } })
      },
      '$route.query.q': function (val) {
        this.q = val
      },
    },
    mounted () {
      this.getSummary()
      this.getResults()
    },
    methods: {
      setStatus (val) {
        this.status = val
      },
      setSummary (data) {
        this.summary = data
      },
      getSummary () {
        axios
          .get('/v1/user/message/summary')
          .then(response => {
            this.setSummary(response.data.data)
          })
          .catch(e => {
            console.error(e)
          })
      },
      search () {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.getResults()
        }, 700)
      },
      setPesan (data) {
        this.Pesan = data
      },
      getResults (page = this.page) {
        this.isLoading = false
        const filter = 'filter[status][is]=read'
        const filterUnread = 'filter[status][is]=unread'
        const params = {
          q: this.q,
          inbox: 0,
          status: this.status,
          page: this.page,
          sort: '-id',
          entities: 'to,from',
        }
        if (this.status === '' || this.status === 'read') {
          axios
            .get('/v1/user/message?', { params: params }, { filter: filter })
            .then(responsen => {
              this.isLoading = true
              this.setPesan(responsen.data)
            })
            .catch(e => {
              console.error(e)
            })
        } else if (this.status === 'unread') {
          axios
            .get(
              '/v1/user/message?',
              { params: params },
              { filter: filterUnread },
            )
            .then(response => {
              this.isLoading = true
              this.setPesan(response.data)
            })
        }
      },
    },
  }
</script>

<style></style>
